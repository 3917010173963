import React, { FunctionComponent } from "react";
import "./App.scss";

const App: FunctionComponent = () => (
  <article role="application" className="App">
    <header className="Header">
      <div className="container">
        <h3>Hyperboost</h3>
        <nav>
          <ul className="unstyled-list">
            <li>
              <a href="/">
                <span className="emoji" role="img" aria-label="Rocket">
                  🚀
                </span>
                Our Apps
              </a>
            </li>
            <li>
              <a href="/">
                <span className="emoji" role="img" aria-label="Microscope">
                  🔬
                </span>
                About Us
              </a>
            </li>
            <li>
              <a href="/">
                <span
                  className="emoji"
                  role="img"
                  aria-label="Incoming Envelope"
                >
                  📨
                </span>
                Newsletter
              </a>
            </li>
            <li>
              <a href="/">
                <span className="emoji" role="img" aria-label="Astronaut">
                  🧑‍🚀
                </span>
                Support
              </a>
            </li>
            <li>
              <a href="/">
                <span className="emoji" role="img" aria-label="Astronaut">
                  🛰
                </span>
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <section role="main" className="Content">
      <article className="container">
        <h2>
          We create amazing <br />
          E-commerce apps
        </h2>
      </article>
    </section>
    <footer className="Footer">
      <div className="container">
        <nav>
          <ul className="unstyled-list">
            <li>
              <a href="/">Terms & Conditions</a>
            </li>
            <li>
              <a href="/">Privacy Policy</a>
            </li>
            <li>
              <a href="/">Support</a>
            </li>
            <li>
              <a href="/">Contact</a>
            </li>
          </ul>
        </nav>
        <p>&copy; 2020 Hyperboost</p>
      </div>
    </footer>
  </article>
);

export default App;
